import { createStore } from 'framework7';
import { http } from '../api/config.js';

const contact = createStore({
  state: {
    item: {},
    list: [],
  },
  actions: {
//Mutations
    set_item({ state }, item) {
      state.item = item;
    },
//End mutations
    async getSelfData({ dispatch }) {
      let response = [];
      let result = {};

      result = await http.get(`contact/self/data`);
      if (result.status === 200) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },
    async getContactData({ dispatch }, contactId) {
      let response = [];
      let result = {};

      if (contactId == 0) {
        result = { 
          error: 400,
          status: 400,
          data:{
            messages: {
              error: 'No se encontro información [0]',
              status: 400,
            }  
          },
        }
        return result;
      }
      
      result = await http.get(`contact/data/${contactId}`);
      if (result.status === 200) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },
    async getContactAddress({ dispatch }, contactId) {
      let response = [];
      let result = {};

      if (contactId == 0) {
        result = { 
          error: 400,
          status: 400,
          data: {
            messages: {
              error: 'No se encontro información [0]',
              status: 400,
            }  
          },
        }
        return result;
      }

      result = await http.get(`contact/address/data/${contactId}`);
      if (result.status === 200) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },
    /*  */
    async createSecured ({ dispatch }, data) {
      let response = [];
      let result = {};

      result = await http.post(`/contact/create/secured`, data, { headers: {'content-type': 'application/x-www-form-urlencoded'} });
      if (result.status === 200) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },
    async modifyData({ dispatch }, data) {
      let response = [];
      let result = {};

      result = await http.post(`contact/modify`, data, { headers: {'content-type': 'application/x-www-form-urlencoded'} });
      if (result.status === 200) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },
    async modifyAddressBookData({ dispatch }, data) {
      let response = [];
      let result = {};

      result = await http.post(`contact/address/modify`, data, { headers: {'content-type': 'application/x-www-form-urlencoded'} });
      if (result.status === 200) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },
  },
  getters: {
    Item({ state }) {
      return state.item;
    }
  },
});

export default contact;
