
let fixedHolidays = {
  "newYear" : {
    month: 1,
    date: 1,
  },
  "kingDay" : {
    month: 4,
    date: 27,
  },
  "christmas" : {
    month: 12,
    date: 25,
  },
  "afterChristmas" : {
    month: 12,
    date: 26,
  },
  searchDate(holidayName, searchDate) {
    var now = new Date(searchDate);
    return ((now.getMonth() + 1) == this[holidayName].month && now.getDate() == this[holidayName].date);
  }
};

export default fixedHolidays;
