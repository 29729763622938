import { createStore } from 'framework7';
import { http } from '../api/config.js';

const loanInvoices = createStore({
  state: {
    item: {},
    list: [],
  },
  actions: {
//Mutations
    set_item({ state }, item) {
      state.item = item;
    },
//End mutations
    async getDetails({ dispatch }, invoiceId) {
      let response = [];
      let result = {};

      result = await http.get(`loan/invoice/details/${invoiceId}`);
      if (result.status === 200) {
        response = result.data;
        return response;
      }
      else {
        return result;
      }
    },
  },
  getters: {
    Item({ state }) {
      return state.item;
    }
  },
});

export default loanInvoices;
